import React from "react";
import styled, { css } from "styled-components";
import { businessAreas } from "../Content";
import Text from "../Atoms/Text";
import ElementTitle from "../Molecules/ElementTitle";
import { theme } from "../Theme";

const StyledBusinessAreas = styled.div`
  width: 100%;

  ${props =>
    props.cn === "businessAreas" &&
    css`
      @media (min-width: 961px) {
        padding-left: 6em;
        padding-right: 6em;
        margin-bottom: 1.5em;
      }

      @media (min-width: 1025px) {
        margin-bottom: 0.5em;
      }
    `};

  ${props =>
    props.cn === "container" &&
    css`
      justify-content: center;
      width: 100%;
    `};
`;

const StyledBusinessAreasItem = styled.div`
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.xLText};
  font-weight: bold;
  width: 50%;
  height: 6.5em;
  background-size: cover;

  ${businessAreas.pictureArray.map((post, index) => {
    return `:nth-child(${index + 1}) {
      background-image: ${theme.gradientBlue}, url(${post});
    }`;
  })}

  @media (min-width: 481px) {
    height: 10em;
  }

  @media (min-width: 961px) {
    width: 25%;
    height: 8em;
    box-shadow: 0.3em 0.3em 0.2em 0.05em #999;
  }

  @media (min-width: 1025px) {
    width: 23%;
    margin: 0em 0.75em;
    margin-bottom: 1.5em;
  }

  @media (min-width: 1281px) {
    width: 20%;
    height: 10em;
  }
`;

const BusinessAreas = () => {
  const BusinessAreasMapped = businessAreas.textArray.map((post, index) => {
    return (
      <StyledBusinessAreasItem key={index}>
        <Text text={post} cn="white" />
      </StyledBusinessAreasItem>
    );
  });

  return (
    <>
      <StyledBusinessAreas cn="businessAreas">
        <ElementTitle
          text1={businessAreas.title1}
          text2={businessAreas.title2}
        />
        <StyledBusinessAreas cn="container">
          {BusinessAreasMapped}
        </StyledBusinessAreas>
      </StyledBusinessAreas>
    </>
  );
};

export default BusinessAreas;
