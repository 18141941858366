import React from "react";
import Banner from "../Organisms/Banner";
import JobsList from "../Organisms/JobsList";
import { jobsBanner1 } from "../Content";

const Jobs = ({ refTop, refDown, width, posts }) => {
  return (
    <>
      <Banner
        refDown={refDown}
        refTop={refTop}
        width={width}
        first={jobsBanner1}
      />
      <JobsList posts={posts} refDown={refDown} second />
    </>
  );
};

export default Jobs;
