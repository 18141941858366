import React from "react";
import styled, { css } from "styled-components";
import Button from "../Atoms/Button";
import Text from "../Atoms/Text";

const StyledJobsBannerItem = styled.div`
  flex-direction: column;
  justify-content: center;
  padding: 1.5em 3em;
  color: white;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 18em;
  margin: 1em 0em;

  @media (min-width: 961px) {
    width: 35%;
    padding: 3em 3em;
    margin: 1em;
    margin-bottom: 2em;
    box-shadow: 0.3em 0.3em 0.2em 0.05em #999;
  }

  ${props =>
    props.cn === "jobsBannerItem" &&
    css`
      @media (min-width: 481px) {
        width: 50%;
      }

      @media (min-width: 961px) {
        min-height: 20em;
      }

      @media (min-width: 1281px) {
        width: 25%;
        padding: 3em 3em;
        margin: 2em;
        min-height: 22em;
      }
    `};

  ${props =>
    props.cn === "jobsBannerItemTwo" &&
    css`
      @media (min-width: 481px) {
        min-height: 20em;
        width: 50%;
      }

      @media (min-width: 641px) {
        min-height: 22em;
      }

      @media (min-width: 961px) {
        width: 40%;
      }

      @media (min-width: 1025px) {
        width: 35%;
      }

      @media (min-width: 1281px) {
        width: 20%;
        margin: 1em;
        margin-bottom: 3em;
      }

      @media only screen and (min-width: 1281px) and (min-resolution: 1.25dppx) {
        font-size: 90%;
        margin: 1em;
        margin-bottom: 3em;
        width: 23%;
      }
    `};
`;

const StyledGreyWhiteButton = styled.div`
  padding: 3% 0%;
  text-transform: uppercase;

  @media only screen and (min-width: 1281px) and (min-resolution: 1.25dppx) {
    font-size: 100%;
`;

const StyledWhiteText = styled.div`
  padding: 3% 0%;
  width: 100%;
  font-size: ${props => props.theme.mediumText};
  line-height: 1.4;

  ${props =>
    props.cn === "text1" &&
    css`
      justify-content: flex-start;
      text-transform: uppercase;
      text-align: start;
      font-weight: bold;
      font-size: ${props => props.theme.largeText};
      line-height: normal;
    `};
`;

const StyledWhiteButton = styled.div`
  justify-content: center;
  font-size: 75%;
  width: 100%;
  padding: 3% 0%;
`;

const JobsBannerItem = ({ cn, style, text1, text2, text3, text4, link }) => {
  return (
    <StyledJobsBannerItem cn={cn} style={style}>
      <StyledGreyWhiteButton cn="greyWhiteButton">
        <Button cn="greyWhite" button={text1} />
      </StyledGreyWhiteButton>
      <StyledWhiteText cn="text1">
        <Text cn="white" text={text2} />
      </StyledWhiteText>
      <StyledWhiteText>
        <Text cn="white" text={text3} />
      </StyledWhiteText>
      <StyledWhiteButton cn="whiteButton">
        <Button cn="white" button={text4} link={link} />
      </StyledWhiteButton>
    </StyledJobsBannerItem>
  );
};

export default JobsBannerItem;
