import React from "react";
import styled, { css } from "styled-components";
import SingleBanner from "../Molecules/SingleBanner";
import ScrollButton from "../Atoms/ScrollButton";

const StyledBanner = styled.div`
  width: 100%;
  background-size: cover;

    ${props =>
      !props.parent &&
      css`
        @media (min-width: 641px) {
          padding: 1em 2em;
          min-height: 30em;
        }

        @media (min-width: 961px) {
          font-size: ${props => props.theme.largeText};
        }
      `};

    ${props =>
      props.color === "blue" &&
      css`
        background-image: ${props => props.theme.gradientBlue},
          url(${props => props.picture});
      `}

    ${props =>
      props.color === "grey" &&
      css`
        background-image: ${props => props.theme.gradientGrey},
          url(${props => props.picture});
      `}

    ${props =>
      !props.color &&
      css`
        background-image: url(${props => props.picture});
      `}

    ${props =>
      props.half &&
      css`
        @media (min-width: ${props => props.half}px) {
          width: 50%;
        }
      `};

    ${props =>
      props.cn === "homeBanner1" &&
      css`
        min-height: 90vh;

        @media (min-width: 481px) {
          min-height: 45vh;
        }
      `};

    ${props =>
      props.cn === "homeBanner1A" &&
      css`
        @media (min-width: 641px) {
          padding: 1em 2em;
          background-position-x: 20%;
        }
      `};

    ${props =>
      props.cn === "homeBanner1B" &&
      css`
        @media (min-width: 641px) {
          padding: 1em 2em;
          background-position-y: 25%;
        }
      `};

    ${props =>
      props.cn === "homeBanner2" &&
      css`
        justify-content: center;
      `};

    ${props =>
      (props.cn === "homeBanner2A" || props.cn === "homeBanner2B") &&
      css`
        @media (min-width: 641px) {
          padding: 1em 2em;
        }

        @media (min-width: 1281px) {
          min-height: 25em;
        }
      `};

    ${props =>
      props.cn === "homeBanner2A" &&
      css`
        background-position-x: 50%;
        order: 2;

        @media (min-width: 961px) {
          order: 1;
        }
      `};

    ${props =>
      (props.cn === "homeBanner2B" || props.cn === "aboutBanner3B") &&
      css`
        order: 1;
        min-height: 15em;

        @media (min-width: 481px) {
          min-height: 20em;
        }

        @media (min-width: 641px) {
          background-position-y: 40%;
        }

        @media (min-width: 961px) {
          order: 2;
          background-position-x: 90%;
        }
      `};

    ${props =>
      props.cn === "jobsBanner1" &&
      css`
        min-height: 18em;

        @media (min-width: 481px) {
          background-position-y: 40%;
          padding-bottom: 1em;
        }

        @media (min-width: 641px) {
          padding: 1em 2em;
          padding-bottom: 0em;
        }

        @media (min-width: 961px) {
          padding: 0em 4em;
        }

        @media (min-width: 1281px) {
          background-position-y: 25%;
        }
      `};

    ${props =>
      props.cn === "candidatesBanner1" &&
      css`
        min-height: 45vh;

        @media (min-width: 641px) {
          padding: 1em 2em;
        }
      `};

      ${props =>
        props.cn === "bannerColumns" &&
        css`
          padding: 0em 0.5em;
          margin-bottom: 2em;
          background-position-x: 50%;

          @media (min-width: 481px) {
            padding-bottom: 1em;
          }

          @media (min-width: 641px) {
            padding-bottom: 0em;
            margin-bottom: 0em;
          }

          @media (min-width: 961px) {
            padding: 0em 4em;
          }

          @media (min-width: 1025px) {
            background-position-y: 50%;
          }

          @media (min-width: 1281px) {
            padding: 0em 12em;
          }
        `}

    ${props =>
      (props.cn === "candidatesBanner3A" ||
        props.cn === "candidatesBanner3B" ||
        props.cn === "candidatesBanner4A" ||
        props.cn === "candidatesBanner4B" ||
        props.cn === "companiesBanner3A" ||
        props.cn === "companiesBanner3B") &&
      css`
        @media (min-width: 641px) {
          padding: 1em 2em;
        }

        @media (min-width: 1281px) {
          padding: 1em 6em;
        }
      `};

    ${props =>
      props.cn === "candidatesBanner4A" &&
      css`
        @media (min-width: 481px) {
          background-position-x: 20%;
        }
      `};

    ${props =>
      props.cn === "companiesBanner1" &&
      css`
        background-position-y: 15%;
        min-height: 45vh;

        @media (min-width: 641px) {
          padding: 1em 2em;
        }
      `};

    ${props =>
      props.cn === "companiesBanner3A" &&
      css`
        @media (min-width: 641px) {
          background-position-x: 20%;
        }
      `};

    ${props =>
      props.cn === "companiesBanner3B" &&
      css`
        @media (min-width: 641px) {
          background-position-x: 60%;
        }
      `};

    ${props =>
      props.cn === "aboutBanner1" &&
      css`
        @media (min-width: 641px) {
          padding: 1em 2em;
        }

        @media (min-width: 1025px) {
          padding: 0em 5em;
        }

        @media (min-width: 1281px) {
          padding: 0em 20em;
        }
      `};

    ${props =>
      (props.cn === "aboutBanner3A" || props.cn === "aboutBanner3B") &&
      css`
        @media (min-width: 481px) {
          padding: 0em 2em;
        }

        @media (min-width: 641px) {
          padding: 0em 3em;
        }

        @media (min-width: 961px) {
          padding: 0em 4em;
        }

        @media (min-width: 1025px) {
          padding: 0em 6em;
        }
      `};

    ${props =>
      props.cn === "aboutBanner3A" &&
      css`
        @media (min-width: 641px) {
          background-position-y: 40%;
        }

        @media (min-width: 1025px) {
          background-position-y: 60%;
        }
      `};

    ${props =>
      props.width &&
      css`
        justify-content: center;

        @media (min-width: 1025px) {
          min-height: 88vh;
        }
      `}
`;

const Banner = ({ parent, first, second, width, refDown, refTop }) => {
  if (parent) {
    return (
      <StyledBanner {...parent.theProps} width={width}>
        <StyledBanner {...first.theProps}>
          <SingleBanner {...first} />
        </StyledBanner>

        {width > 1024 ? (
          <ScrollButton refDown={refDown} refTop={refTop} />
        ) : null}

        <StyledBanner {...second.theProps}>
          <SingleBanner {...second} />
        </StyledBanner>
      </StyledBanner>
    );
  } else {
    return (
      <>
        <StyledBanner {...first.theProps} width={width}>
          <SingleBanner {...first} />
          {width > 1024 ? (
            <ScrollButton refDown={refDown} refTop={refTop} />
          ) : null}
        </StyledBanner>
      </>
    );
  }
};

export default Banner;
