import React from "react";
import styled, { css } from "styled-components";
import Text from "../Atoms/Text";

const StyledElementTitle = styled.div`
  ${props =>
    props.cn === "elementTitle" &&
    css`
      flex-direction: column;
      width: 100%;

      @media (min-width: 1281px) {
        font-size: ${props => props.theme.mediumText};
      }
    `};

  ${props =>
    props.cn === "title1" &&
    css`
      justify-content: center;
      width: 100%;
      padding: 0.1em;
      font-size: ${props => props.theme.xLText};
      font-style: italic;
      padding-top: 0.5em;
    `};

  ${props =>
    props.cn === "title2" &&
    css`
      justify-content: center;
      width: 100%;
      padding: 0.1em;
      font-size: ${props => props.theme.xXLText};
      padding-bottom: 0.5em;

      @media (min-width: 1281px) {
        font-size: ${props => props.theme.hugeText};
      }
    `};
`;

const ElementTitle = ({ refDown, text1, text2 }) => {
  return (
    <StyledElementTitle cn="elementTitle" ref={refDown}>
      <StyledElementTitle cn="title1">
        <Text cn="blue" text={text1} />
      </StyledElementTitle>
      <StyledElementTitle cn="title2">
        <Text cn="grey" text={text2} />
      </StyledElementTitle>
    </StyledElementTitle>
  );
};

export default ElementTitle;
