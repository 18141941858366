import React from "react";
import styled, { css } from "styled-components";
import { blogPosts } from "../Content";

const StyledBlogPreviews = styled.a`
  ${props =>
    props.cn === "blogPreviews" &&
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      text-decoration: none;

      @media (min-width: 961px) {
        width: 90%;
        padding: 1em 0em;
      }

      @media (min-width: 1025px) {
        font-size: 130%;
      }

      @media (min-width: 1281px) {
        width: 70%;

        :img {
          max-height: 17em;
          width: 35%;
        }
      }
    `};

  ${props =>
    props.cn === "picture" &&
    css`
      width: 100%;
      object-fit: cover;

      @media (min-width: 961px) {
        width: 50%;
      }

      @media (min-width: 1281px) {
        max-height: 17em;
        width: 35%;
      }
    `};

  ${props =>
    props.cn === "container" &&
    css`
      color: ${props => props.theme.colorTwo};
      padding: 1em 1em;

      @media (min-width: 641px) {
        padding: 1em 5em;
        padding-bottom: 5em;
      }

      @media (min-width: 961px) {
        width: 50%;
        padding: 2em 2em;
      }
    `};
`;

const StyledBlogPreviewsText = styled.div`
  padding: 0.2em 0em;

      :nth-child(1) {
        fonts-size: ${props => props.theme.mediumText};
        font-style: italic;
        width: 100%;
      }

      :nth-child(2) {
        font-size: ${props => props.theme.mediumText};
        font-weight: bold;
        width: 100%;
      }

      :nth-child(3) {
        width: 100%;
      }

      :nth-child(4) {
        width: 50%;
        padding-top: 0.5em;
        font-style: italic;
      }

      :nth-child(5) {
        width: 50%;
        padding-top: 0.5em;
        font-style: italic;
        justify-content: flex-end;
      }

      @media (min-width: 641px) {
        :nth-child(3) {
          text-align: justify;
        }
      }
`;

const BlogPreviews = () => {
  const blogArticlePreview = blogPosts.map((post, index) => {
    let postBody = Object.values(post[1]).toString();
    postBody = postBody.slice(0, postBody.indexOf(" ", 200)) + "...";

    return (
      <StyledBlogPreviews cn="blogPreviews" href={post[0].link} key={index}>
        <StyledBlogPreviews
          as="img"
          cn="picture"
          src={post[0].picture}
          alt=""
        />
        <StyledBlogPreviews as="div" cn="container">
          <StyledBlogPreviewsText>
            {post[0].titleCategory}
          </StyledBlogPreviewsText>
          <StyledBlogPreviewsText>{post[0].title}</StyledBlogPreviewsText>
          <StyledBlogPreviewsText>{postBody}</StyledBlogPreviewsText>
          <StyledBlogPreviewsText>{post[0].author}</StyledBlogPreviewsText>
          <StyledBlogPreviewsText>{post[0].date}</StyledBlogPreviewsText>
        </StyledBlogPreviews>
      </StyledBlogPreviews>
    );
  });

  return <>{blogArticlePreview}</>;
};

export default BlogPreviews;
