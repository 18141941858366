import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const StyledButton = styled.a`
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  width: auto;
  border-radius: 2em;
  transition: 0.7s;

  ${props =>
    (props.cn === "grey" || props.cn === "header") &&
    css`
      color: ${props => props.theme.colorTwo};
      font-weight: bold;
    `};

  ${props =>
    props.cn === "footer" &&
    css`
      color: white;

      :hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    `};

  ${props =>
    props.cn === "greyWhite" &&
    css`
      color: white;
      background-color: ${props => props.theme.colorTwo};
      padding: 0.3em 0.9em;
      font-weight: bold;
    `};

  ${props =>
    props.cn === "white" &&
    css`
      color: white;
      border: solid 2px white;
      font-size: ${props => props.theme.xLText};
      padding: 0.2em 0.4em;
      font-weight: bold;

      :hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    `};

  ${props =>
    props.cn === "header" &&
    css`
      white-space: unset;
      justify-content: center;

      :hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    `};
`;

const Button = ({ cn, link, button }) => {
  return (
    <>
      {!link && <StyledButton cn={cn}>{button}</StyledButton>}

      {link && link.startsWith("/") && (
        <StyledButton as={Link} cn={cn} to={link}>
          {button}
        </StyledButton>
      )}

      {link && (link.startsWith("http") || link.startsWith("www")) && (
        <StyledButton cn={cn} href={link}>
          {button}
        </StyledButton>
      )}
    </>
  );
};

export default Button;
