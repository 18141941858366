import React from "react";
import Partner from "../Organisms/Partner";
import PersonellBoard from "../Organisms/PersonellBoard";

import { personell } from "../Content";

const Contact = ({ refDown, width, refTop }) => {
  return (
    <>
      <Partner refDown={refDown} />
      <PersonellBoard {...personell} />
    </>
  );
};

export default Contact;
