import React from "react";
import styled, { css } from "styled-components";

const StyledPartnerItem = styled.a`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 1025px) {
    width: 50%;
  }
`;

const StyledPartnerItemImg = styled.img`
  max-width: 100%;

  ${props =>
    props.cn === "picture1" &&
    css`
      height: 1.6em;
      margin-top: 1.2em;

      @media (min-width: 481px) {
        height: 2.2em;
      }

      @media (min-width: 641px) {
        height: 3em;
        margin-top: 2em;
      }

      @media (min-width: 1025px) {
        margin-top: 0em;
      }
    `};

  ${props =>
    props.cn === "picture2" &&
    css`
      height: 7em;

      @media (min-width: 481px) {
        height: 10em;
      }

      @media (min-width: 641px) {
        height: 15em;
      }
    `};

  ${props =>
    props.cn === "picture3" &&
    css`
      height: 1.85em;

      @media (min-width: 481px) {
        height: 2.65em;
      }

      @media (min-width: 641px) {
        height: 3.95em;
      }
    `};

  ${props =>
    props.cn === "picture4" &&
    css`
      height: 4.65em;

      @media (min-width: 481px) {
        height: 6.65em;
      }

      @media (min-width: 641px) {
        height: 10em;
      }
    `};
`;

const PartnerItem = ({ cn, link, picture }) => {
  return (
    <StyledPartnerItem cn={cn} href={link}>
      <StyledPartnerItemImg
        cn={cn}
        src={picture}
        alt="This is the logo of one of our partners"
      />
    </StyledPartnerItem>
  );
};

export default PartnerItem;
