import React from "react";
import styled, { css } from "styled-components";

const StyledReviewPicture = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${props =>
    props.cn === "partner" &&
    css`
      height: 4em;
      width: auto;
    `};
`;

const StyledReviewPictureImg = styled.img`
  height: 100%;
  object-fit: cover;

  ${props =>
    props.cn === "employee" &&
    css`
      border-radius: 50%;
    `};
`;

const ReviewPicture = ({ cn, image }) => {
  return (
    <StyledReviewPicture cn={cn}>
      <StyledReviewPictureImg
        cn={cn}
        src={image}
        alt="Employee or client representative"
      />
    </StyledReviewPicture>
  );
};

export default ReviewPicture;
