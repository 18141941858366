import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    flex-wrap: wrap;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
    font-family: Raleway ExtraBold, sans-serif;
    font-size: 100%;
    margin: 0px;
    padding: 0px;
  }

  div {
    display: flex;
  }
`;

export default GlobalStyle;
