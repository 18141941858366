import React from "react";
import styled from "styled-components";
import Picture from "../Media/white-down-arrow-png-2.png";

const StyledScrollButton = styled.img`
  position: absolute;
  align-self: flex-end;
  height: 7rem;

  :hover {
    height: 10rem;
    transition-duration: 0.5s;
    cursor: pointer;
  }
`;

const ScrollButton = ({ refDown, refTop, style }) => {
  const scrollToRef = (refDown, refTop) =>
    window.scrollTo(0, refDown.current.offsetTop - refTop.current.offsetHeight);

  return (
    <StyledScrollButton
      onClick={() => scrollToRef(refDown, refTop)}
      src={Picture}
      alt="Arrow to scroll down"
      style={style}
    />
  );
};

export default ScrollButton;
