import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import ReactGA from "react-ga";
import Helmet from 'react-helmet';
import CookieConsent from "react-cookie-consent";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./GlobalStyle";
import { theme } from "./Theme";
import { blogArticleLinksRoutes } from "./Organisms/BlogArticleLinks";
import Header from "./Organisms/Header";
import Footer from "./Organisms/Footer";
import ScrollToTop from "./Atoms/ScrollToTop";
import Home from "./Pages/Home";
import Jobs from "./Pages/Jobs";
import Candidates from "./Pages/Candidates";
import Companies from "./Pages/Companies";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import { menu, cookieAlert, cookies, notFound, aboutBanner1 } from "./Content";
import useTeamTailorData from "./useTeamTailorData";

export const StyledApp = styled.div`
  ${props =>
    props.cn === "app" &&
    css`
      justify-content: center;
      width: 100%;
      max-width: 1920px;
      margin: auto;
      margin-top: 10vh;

      @media (min-width: 961px) {
        margin-top: 8vh;
      }

      @media (min-width: 1025px) {
        margin-top: 12vh;
      }
    `};

  ${props =>
    props.cn === "notFound" &&
    css`
      height: 50vh;

      @media (min-width: 1025px) {
        min-height: 57.8vh;
      }
    `};

  ${props =>
    props.cn === "notFoundH1" &&
    css`
      color: black;
      margin-top: 5em;
      font-weight: bold;
      font-size: 2em;
    `};
`;

const App = () => {
  const posts = useTeamTailorData()
  const [consent, setConsent] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [oldLocation, setOldLocation] = useState(null);

  const refDown = useRef(null);
  const refTop = useRef(null);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(window.innerWidth);
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);

    return _ => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    if (!consent) {
      if (
        document.cookie
          .split(";")
          .filter(item => item.includes("CookieConsent=true")).length
      ) {
        setConsent(true);
        ReactGA.initialize("UA-154153328-1");
      }
    }
  }, [consent]);

  function debounce(fn, ms) {
    let timer;
    return _ => {
      clearTimeout(timer);
      timer = setTimeout(_ => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const usePageViews = () => {
    let location = useLocation();
    useEffect(() => {
      if (oldLocation !== location.pathname) {
        ReactGA.pageview(location.pathname);
        setOldLocation(location.pathname);
      }
    }, [location]);
  };

  usePageViews();

  console.log(posts)

  return (
    <StyledApp cn="app">
      <Helmet titleTemplate="%s - Uppstuk" defaultTitle="Uppstuk - Konsulter & rekrytering">
        <meta name="description" content={aboutBanner1.text3} />
      </Helmet>
      <ScrollToTop />
      <GlobalStyle />
      <Header width={width} refTop={refTop} menu={menu} />
      <CookieConsent
        buttonText={cookies.accept}
        onAccept={() => {
          ReactGA.initialize("UA-154153328-1");
          setConsent(true);
        }}
        enableDeclineButton
        declineButtonText={cookies.decline}
        onDecline={() => {
          alert(cookieAlert.text1);
        }}
      >
        {cookies.consentText}
      </CookieConsent>
      <Switch>
        <Route exact path="/">
          <Home refTop={refTop} refDown={refDown} width={width} posts={posts} />
        </Route>

        <Route exact path="/jobb">
          <Jobs refTop={refTop} refDown={refDown} width={width} posts={posts} />
        </Route>

        <Route exact path="/(kandidat|student)/">
          <Candidates
            refDown={refDown}
            refTop={refTop}
            width={width}
            posts={posts}
          />
        </Route>

        <Route exact path="/foretag">
          <Companies refDown={refDown} refTop={refTop} width={width} />
        </Route>

        <Route exact path="/nyheter">
          <Blog refDown={refDown} refTop={refTop} width={width} />
        </Route>

        {blogArticleLinksRoutes}

        <Route exact path="/kontakt">
          <Contact refDown={refDown} width={width} refTop={refTop} />
        </Route>

        <Route exact path="/om-uppstuk">
          <About refDown={refDown} refTop={refTop} width={width} />
        </Route>

        <Route>
          <StyledApp cn="notFound">
            <Helmet title="404" />
            <StyledApp cn="notFoundH1">{notFound}</StyledApp>
          </StyledApp>
        </Route>
      </Switch>
      <Footer />
    </StyledApp>
  );
};

export default () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
