import React, { useState } from "react";
import styled, { css } from "styled-components";
import Menu from "../Molecules/Menu";
import Logo from "../Atoms/Logo";

const StyledHeader = styled.div`
  ${props =>
    props.cn === "header" &&
    css`
      display: flex;
      position: fixed;
      z-index: 1;
      top: 0;
      background-color: white;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-height: 10vh;
      max-width: 1920px;

      @media (min-width: 961px) {
        min-height: 8vh;
      }

      @media (min-width: 1025px) {
        min-height: 12vh;
      }
    `};

  ${props =>
    props.cn === "logo" &&
    css`
      flex-direction: column;
      justify-content: center;
      min-height: 10vh;
      max-width: 40%;
      padding-left: 0.5em;

      @media (min-width: 481px) {
        padding-left: 1em;
      }

      @media (min-width: 641px) {
        padding-left: 2em;
      }

      @media (min-width: 961px) {
        padding-left: 3em;
        min-height: 100%;
      }

      @media (min-width: 1025px) {
        width: 45vw;
        min-height: 100%;
        min-width: 50%;
        width: 50%;
      }

      @media (min-width: 1281px) {
        padding-left: 3em;
      }
    `};

  ${props =>
    props.cn === "right" &&
    css`
      height: 100%;
      min-width: 60%;
      align-items: stretch;

      @media (min-width: 481px) {
        font-size: 130%;
      }

      @media (min-width: 961px) {
        font-size: 160%;
        min-height: 100%;
      }

      @media (min-width: 1025px) {
        width: 45vw;
        min-height: 100%;
        min-width: 50%;
        width: 50%;
      }

      @media (min-width: 1281px) {
        font-size: 170%;
      }
    `};

  ${props =>
    props.cn === "mobileMenuContainer" &&
    css`
      height: 100%;
      width: 60%;
      justify-content: flex-end;
    `};

  ${props =>
    props.cn === "mobileMenuList" &&
    css`
      position: absolute;
    `};

  ${props =>
    props.cn === "mobileMenuButton" &&
    css`
      padding-right: 0.3em;
      align-items: center;
    `};
`;

const Header = ({ width, refTop, menu }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <StyledHeader as="header" cn="header" ref={refTop}>
      <StyledHeader cn="logo">
        <Logo />
      </StyledHeader>
      {width < 641 ? (
        <StyledHeader cn="mobileMenuContainer">
          <StyledHeader
            cn="mobileMenuList"
            menu={menu}
            onClick={() => setOpen(!isOpen)}
            style={
              isOpen === true
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
          >
            <Menu
              cn="mobileMenuList"
              menu={menu}
              style={
                isOpen === true
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            />
          </StyledHeader>
          <StyledHeader cn="mobileMenuButton" onClick={() => setOpen(!isOpen)}>
            <Menu cn="mobileMenuButton" menu={menu} />
          </StyledHeader>
        </StyledHeader>
      ) : (
        <StyledHeader cn="right">
          <Menu cn="menuTitle" menu={menu} />
          <Menu cn="menuBottom" menu={menu} />
        </StyledHeader>
      )}
    </StyledHeader>
  );
};

export default Header;
