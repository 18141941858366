import React from "react";
import styled, { css } from "styled-components";

const StyledPersonellItem = styled.div`
  max-width: 100%;
  width: 32em;
  color: ${props => props.theme.colorTwo};
  padding: 1.5em 1em;
  align-items: center;
  justify-content: center;

  @media (min-width: 481px) {
    font-size: ${props => props.theme.mediumText};
  }

  @media (min-width: 641px) {
    padding: 1.5em 1em;
  }

  @media (min-width: 961px) {
    font-size: ${props => props.theme.largeText};
  }

  @media (min-width: 1025px) {
    padding: 1.5em 2em;
  }

  @media (min-width: 1281px) {
    margin-left: 0em;
    padding: 1.5em 4em;
    hyphens: none;
  }

  @media only screen and( min-resolution: 1.25dppx ) and (min-width: 1281px) {
    margin-left: 0em;
  }
`;

const StyledPersonellItemPicture = styled.div`
  ${props =>
    props.cn === "picture" &&
    css`
      width: 35%;
      justify-content: flex-end;

      @media (min-width: 641px) {
        padding-left: 7em;
      }

      @media (min-width: 961px) {
        padding-left: 0em;
      }
    `};

  ${props =>
    props.cn === "pictureImg" &&
    css`
      height: 6em;
      border-radius: 50%;
      object-fit: cover;

      @media (min-width: 961px) {
        height: 7em;
      }

      @media (min-width: 1025px) {
        height: 8em;
      }
    `};
`;

const StyledPersonellItemText = styled.div`
  font-size: ${props => props.theme.mediumText};
  width: 100%;

  ${props =>
    props.cn === "container" &&
    css`
      justify-content: center;
      width: 65%;
      padding-left: 1.5em;

      @media (min-width: 641px) {
        padding-left: 2em;
      }
    `};

  ${props =>
    props.cn === "item1" &&
    css`
      font-size: ${props => props.theme.largeText};
      font-weight: bold;
    `};

  ${props =>
    props.cn === "item2" &&
    css`
      font-weight: bold;
      margin-top: 0.25em;
    `};

  ${props =>
    props.cn === "item3" &&
    css`
      word-break: break-word;
      margin-top: 0.25em;

      @media (min-width: 641px) {
        margin-top: 0.5em;
      }
    `};

  ${props =>
    props.cn === "item5" &&
    css`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      text-decoration: none;
      color: ${props => props.theme.colorTwo};
      text-decoration: underline;
      margin-top: 0.25em;

      @media (min-width: 641px) {
        margin-top: 0.5em;
      }
    `};

    ${props =>
      props.cn === "blurb" &&
      css`
        margin-top: 1.5em;
        width: 100%;
        font-style: italic;
        position: relative;
        overflow: visible;

        &::before {
          overflow: visible;
          content: "“";
          font-size: 4rem;
          top: -2.5rem;
          left: -0.5rem;
          position: absolute;
          opacity: 0.5;
        }

        @media (min-width: 961px) {
          &::before {
            font-size: 8rem;
              top: -1.5rem;
              left: -5rem;
            }
          }
        }
      `};
`;

const PersonellItem = ({
  picture,
  text1,
  text2,
  text3,
  text4,
  text5,
  blurb,
  link
}) => {
  return (
    <StyledPersonellItem cn="personellItem">
      <StyledPersonellItemPicture cn="picture">
        <StyledPersonellItemPicture
          as="img"
          cn="pictureImg"
          src={picture}
          alt="Employee or member of the board"
        />
      </StyledPersonellItemPicture>
      <StyledPersonellItemText cn="container">
        <StyledPersonellItemText cn="item1">{text1}</StyledPersonellItemText>
        <StyledPersonellItemText cn="item2">{text2}</StyledPersonellItemText>
        <StyledPersonellItemText cn="item3">{text3}</StyledPersonellItemText>
        <StyledPersonellItemText>{text4}</StyledPersonellItemText>
        <StyledPersonellItemText>
          <StyledPersonellItemText as="a" href={link} cn="item5">
            {text5}
          </StyledPersonellItemText>
        </StyledPersonellItemText>
      </StyledPersonellItemText>

      {blurb && (
        <StyledPersonellItemText cn="blurb">{blurb}</StyledPersonellItemText>
      )}
    </StyledPersonellItem>
  );
};

export default PersonellItem;
