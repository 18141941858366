import React from "react";
import ElementTitle from "../Molecules/ElementTitle";
import Banner from "../Organisms/Banner";
import Partner from "../Organisms/Partner";
import BlogBannerGroup from "../Organisms/BlogBannerGroup";
import BusinessAreas from "../Organisms/BusinessAreas";
import {
  companiesBanner1,
  companiesBanner2,
  companiesBanner3,
  bannerTitleCompanies3
} from "../Content";

const Companies = ({ refTop, refDown, width }) => {
  return (
    <>
      <Banner
        refDown={refDown}
        refTop={refTop}
        width={width}
        first={companiesBanner1}
      />
      <Partner refDown={refDown} />
      <Banner first={companiesBanner2} />
      <ElementTitle {...bannerTitleCompanies3} />
      <Banner parent={companiesBanner3} {...companiesBanner3} />
      <BlogBannerGroup cn="blogBannerGroupB" />
      <BusinessAreas />
    </>
  );
};

export default Companies;
