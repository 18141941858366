import React from "react";
import ElementTitle from "../Molecules/ElementTitle";
import Banner from "../Organisms/Banner";
import Partner from "../Organisms/Partner";
import BlogBannerGroup from "../Organisms/BlogBannerGroup";
import BusinessAreas from "../Organisms/BusinessAreas";
import {
  aboutBanner1,
  aboutBanner2,
  aboutBanner3,
  bannerTitleAbout3
} from "../Content";

const About = ({ refDown, refTop, width }) => {
  return (
    <>
      <Banner
        refDown={refDown}
        refTop={refTop}
        width={width}
        first={aboutBanner1}
      />
      <Partner refDown={refDown} />
      <Banner first={aboutBanner2} />
      <ElementTitle {...bannerTitleAbout3} />
      <Banner parent={aboutBanner3} {...aboutBanner3} />
      <BlogBannerGroup cn="blogBannerGroupB" />
      <BusinessAreas />
    </>
  );
};

export default About;
