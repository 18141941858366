import React from "react";
import styled, { css } from "styled-components";
import Button from "../Atoms/Button";
import Text from "../Atoms/Text";

const StyledFooterColumn = styled.div`
  width: 100%;
  flex-direction: column;
  text-align: center;
  color: white;
  padding: 2em 2em;

  @media (min-width: 481px) {
    width: 50%;
  }

  @media (min-width: 961px) {
    width: 25%;
  }
`;

const StyledFooterColumnContent = styled.div`
  text-decoration: none;

  ${props =>
    (props.cn === "title" ||
      props.cn === "text" ||
      props.cn === "imgContainer") &&
    css`
      display: flex;
      flex-wrap: wrap;
      color: white;
      justify-content: center;
      width: 100%;
      padding: 0.2em 0em;

      @media (min-width: 961px) {
        justify-content: flex-start;
        text-align: start;
      }
    `};

  ${props =>
    props.cn === "text" &&
    css`
      @media (min-width: 1025px) {
        font-size: ${props => props.theme.smallText};
        letter-spacing: 0.02em;
        padding: 0.3em 0em;
      }
    `};

  ${props =>
    props.cn === "title" &&
    css`
      font-size: ${props => props.theme.largeText};
      font-weight: bold;
      padding-bottom: 0.8em;

      @media (min-width: 1025px) {
        font-size: $MediumText;
      }
    `};

  ${props =>
    props.cn === "logo" &&
    css`
      display: flex;
      width: 100%;
      justify-content: center;

      @media (min-width: 961px) {
        justify-content: flex-start;
        text-align: start;
      }
    `};

  ${props =>
    props.cn === "logoImg" &&
    css`
      height: 2.5em;
      margin-bottom: 0.5em;
      transform: rotate(0.7deg);
    `};

  ${props =>
    props.cn === "imgContainer" &&
    css`
      display: flex;
      justify-content: center;
      padding-top: 0.5em;
      padding-left: 1em;

      @media (min-width: 961px) {
        padding-left: 0em;
      }
    `};

  ${props =>
    props.cn === "picture" &&
    css`
      padding-left: 0em;
      padding-right: 1em;
      margin-bottom: 1.5em;

      @media (min-width: 961px) {
        padding-left: 0em;
        padding-right: 1em;
      }

      @media (min-width: 1025px) {
        height: 1.5em;
      }
    `};

  ${props =>
    props.cn === "pictureImg" &&
    css`
      display: flex;
      height: 1.5em;
    `};
`;

const FooterColumn = ({
  cn,
  header,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  facebook,
  instagram,
  linkedIn,
  logo
}) => {
  return (
    <StyledFooterColumn cn={cn}>
      <StyledFooterColumnContent {...header}>
        {cn === "column4" ? (
          <StyledFooterColumnContent {...logo} />
        ) : (
          <Text {...text1} />
        )}
      </StyledFooterColumnContent>
      <StyledFooterColumnContent cn="text">
        <Button {...text2} />
      </StyledFooterColumnContent>
      <StyledFooterColumnContent cn="text">
        <Button {...text3} />
      </StyledFooterColumnContent>

      {cn !== "column3" && (
        <>
          <StyledFooterColumnContent cn="text">
            <Button {...text4} />
          </StyledFooterColumnContent>
          <StyledFooterColumnContent cn="text">
            {cn === "column1" ? <Button {...text5} /> : text5}
          </StyledFooterColumnContent>
        </>
      )}

      {cn === "column4" && (
        <StyledFooterColumnContent cn="text">{text6}</StyledFooterColumnContent>
      )}

      {cn === "column3" && (
        <StyledFooterColumnContent cn="imgContainer">
          <StyledFooterColumnContent {...facebook.link}>
            <StyledFooterColumnContent {...facebook.picture} />
          </StyledFooterColumnContent>
          <StyledFooterColumnContent {...instagram.link}>
            <StyledFooterColumnContent {...instagram.picture} />
          </StyledFooterColumnContent>
          <StyledFooterColumnContent {...linkedIn.link}>
            <StyledFooterColumnContent {...linkedIn.picture} />
          </StyledFooterColumnContent>
        </StyledFooterColumnContent>
      )}
    </StyledFooterColumn>
  );
};

export default FooterColumn;
