import React from "react";
import styled, { css } from "styled-components";

const StyledReviewText = styled.div`
  width: 100%;
  font-size: ${props => props.theme.largeText};
  line-height: 1.3;
  padding: 1em;
  hyphens: auto;
  background-color: #f2f5f7;

  @media (min-width: 1281px) {
    font-size: ${props => props.theme.mediumText};
  }
`;

const StyledReviewTextPart = styled.div`
  width: 100%;

  ${props =>
    props.cn === "text1" &&
    css`
      text-align: left;
      margin-bottom: 1em;

      @media (min-width: 1025px) {
        text-align: justify;
      }
    `};

  ${props =>
    props.cn === "text2" &&
    css`
      @media (min-width: 1281px) {
        width: auto;
      }
    `};
`;

const ReviewText = ({ text1, text2 }) => {
  return (
    <StyledReviewText>
      <StyledReviewTextPart cn="text1">{text1}</StyledReviewTextPart>
      <StyledReviewTextPart cn="text2">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{text2}
      </StyledReviewTextPart>
    </StyledReviewText>
  );
};

export default ReviewText;
