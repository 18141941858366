import React from "react";
import styled, { css } from "styled-components";
import Text from "../Atoms/Text.js";
import Button from "../Atoms/Button.js";

const StyledSingleBanner = styled.div`
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  padding: 1em 2em;
  hyphens: auto;
  margin: auto;
`;

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const StyledText = styled.div`
  ${props =>
    props.number === "one" &&
    css`
      width: 100%;
      font-style: italic;
      font-size: ${props => props.theme.largeText};
    `};

  ${props =>
    props.number === "two" &&
    css`
      width: 100%;
      font-weight: bold;
      font-size: ${props => props.theme.xXLText};
    `};

  ${props =>
    props.number === "three" &&
    css`
      width: 100%;
      line-height: 1.5;
      hyphens: auto;
    `};

  ${props =>
    props.center &&
    css`
      justify-content: center;
      text-align: center;
    `};

  ${props =>
    props.special === "columns" &&
    css`
      padding: 0.5em 0em;

      @media (min-width: 641px) {
        display: block;
        column-count: 2;
        column-gap: 2em;
      }

      @media (min-width: 1025px) {
        text-align: justify;
      }
    `};

  ${props =>
    props.number === "three" &&
    props.justify === "1025" &&
    css`
      @media (min-width: 1025px) {
        text-align: justify;
      }
    `};
`;

const StyledSingleBannerButton = styled.div`
  font-weight: bold;
  justify-content: center;
  text-decoration: none;
  font-size: ${props => props.theme.smallText};
  margin: 1em 0.5em;
`;

const SingleBanner = ({
  text1,
  text2,
  text3,
  text4,
  text5,
  link1,
  link2,
  special,
  center,
  justify
}) => {
  return (
    <StyledSingleBanner>
      <StyledContainer>
        <StyledText number="one" center={center}>
          <Text cn="white" text={text1} />
        </StyledText>
        <StyledText number="two" center={center}>
          <Text cn="white" text={text2} />
        </StyledText>
      </StyledContainer>
      <StyledText
        number="three"
        center={center}
        special={special}
        justify={justify}
      >
        <Text cn="white" text={text3} />
      </StyledText>

      {link1 && (
        <StyledSingleBannerButton>
          <Button cn="white" link={link1} button={text4} />
        </StyledSingleBannerButton>
      )}

      {link2 && (
        <StyledSingleBannerButton>
          <Button cn="white" link={link2} button={text5} />
        </StyledSingleBannerButton>
      )}
    </StyledSingleBanner>
  );
};

export default SingleBanner;
