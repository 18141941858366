import React from "react";
import styled from "styled-components";
import { footer } from "../Content";
import FooterColumn from "./FooterColumn";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.colorTwo};

  @media (min-width: 961px) {
    padding: 0em 5em;
  }

  @media (min-width: 1025px) {
    padding: 0em 10em;
  }

  @media (min-width: 1281px) {
    font-size: ${props => props.theme.largeText};
    padding: 0em 10em;
  }
`;

const Footer = () => {
  const footerMapped = Object.keys(footer).map((item, index) => {
    return <FooterColumn key={index} {...footer[item]} />;
  });

  return <StyledFooter>{footerMapped}</StyledFooter>;
};

export default Footer;
