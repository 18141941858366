import React from "react";
import styled, { css } from "styled-components";
import ElementTitle from "../Molecules/ElementTitle";
import PersonellItem from "../Molecules/PersonellItem";

const StyledPersonellBoard = styled.div`
  width: 100%;

  ${props =>
    (props.cn === "personellBanner" || props.cn === "boardBanner") &&
    css`
      padding: 2em 0em;

      @media (min-width: 641px) {
        padding: 2em 2em;
      }

      @media (min-width: 1281px) {
        padding: 3em 3em;
      }
    `};

  ${props =>
    props.cn === "personellBanner" &&
    css`
      background-color: #f2f5f7;
    `};
`;

const StyledPersonellBoardContent = styled.div`
  width 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 961px) {
    padding-top: 2em;
  }
`;

const PersonellBoard = ({ cn, title, personell }) => {
  return (
    <StyledPersonellBoard cn={cn}>
      <ElementTitle {...title} />
      <StyledPersonellBoardContent>
        {personell.map(post => (
          <PersonellItem {...post} />
        ))}
      </StyledPersonellBoardContent>
    </StyledPersonellBoard>
  );
};

export default PersonellBoard;
