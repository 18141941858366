import React from "react";
import styled, { css } from "styled-components";
import BlogBanner from "../Molecules/BlogBanner";
import ElementTitle from "../Molecules/ElementTitle";
import ScrollButton from "../Atoms/ScrollButton";
import { blogPosts, blogBannerGroupTitle } from "../Content";
import { createCloudinaryImage } from "./BlogCloudinary";

const StyledBlogBannerGroup = styled.div`
  ${props =>
    (props.cn === "blogBannerGroupA" || props.cn === "blogBannerGroupB") &&
    css`
      width: 100%;
      justify-content: center;

      @media (min-width: 481px) {
        height: auto;
      }

      @media (min-width: 641px) {
        font-size: ${props => props.theme.largeText};
      }
    `};

  ${props =>
    props.cn === "blogBannerGroupA" &&
    css`
      @media (min-width: 1025px) {
        min-height: 88vh;
      }
    `};

  ${props =>
    props.cn === "title" &&
    css`
      width: 100%;

      @media (min-width: 961px) {
        margin-top: 1em;
      }
    `};

  ${props =>
    props.cn === "halfDiv" &&
    css`
      width: 100%;

      @media (min-width: 1025px) {
        width: 50%;
        min-height: 27em;
      }

      @media (min-width: 1281px) {
        min-height: 30em;
      }
    `};

  ${props =>
    props.cn === "quarterDiv" &&
    css`
      width: 100%;

      @media (min-width: 481px) {
        width: 33.33%;
      }

      @media (min-width: 1025px) {
        width: 100%;
        height: 50%;
      }
    `};

  ${props =>
    props.cn === "eigthDiv" &&
    css`
      width: 100%;

      @media (min-width: 481px) {
        width: 33.33%;
      }

      @media (min-width: 1025px) {
        width: 50%;
        height: 50%;
      }
    `};

  ${props =>
    props.cn === "halfContainer" &&
    css`
      width: 100%;

      @media (min-width: 1025px) {
        width: 50%;
      }
    `};
`;

let blogPostsMappedTemp = blogPosts.map(post => {
  return post;
});

let blogPostsMapped = blogPostsMappedTemp.splice(0, 4).map((post, index) => {
  let pictureUrl = post[0].picture

  if (post[0].pictureCloudinaryFileName) {
    pictureUrl = createCloudinaryImage({
      fileName: post[0].pictureCloudinaryFileName,
    })
  }

  if (index === 0) {
    return (
      <BlogBanner
        text1={post[0].titleCategory.toUpperCase()}
        text2={post[0].title}
        text3={
          Object.values(post[1])
            .toString()
            .slice(0, 200) + "..."
        }
        link={post[0].link}
        picture={pictureUrl}
      />
    );
  } else {
    return (
      <BlogBanner
        text1={post[0].titleCategory.toUpperCase()}
        text2={post[0].title}
        link={post[0].link}
        picture={pictureUrl}
      />
    );
  }
});

const BlogBannerGroup = ({ cn, width, refDown, refTop }) => {
  return (
    <>
      {cn === "blogBannerGroupB" && (
        <StyledBlogBannerGroup cn="title">
          <ElementTitle
            text1={blogBannerGroupTitle.text1}
            text2={blogBannerGroupTitle.text2}
          />
        </StyledBlogBannerGroup>
      )}
      <StyledBlogBannerGroup cn={cn}>
        <StyledBlogBannerGroup cn="halfDiv" article="article">
          {blogPostsMapped[0]}
        </StyledBlogBannerGroup>
        <StyledBlogBannerGroup cn="halfContainer">
          <StyledBlogBannerGroup cn="quarterDiv" article="article">
            {blogPostsMapped[1]}
          </StyledBlogBannerGroup>
          <StyledBlogBannerGroup cn="eigthDiv" article="article">
            {blogPostsMapped[2]}
          </StyledBlogBannerGroup>
          <StyledBlogBannerGroup cn="eigthDiv" article="article">
            {blogPostsMapped[3]}
          </StyledBlogBannerGroup>
        </StyledBlogBannerGroup>
        {cn === "blogBannerGroupA" &&
          (width > 1024 ? (
            <ScrollButton refDown={refDown} refTop={refTop} />
          ) : null)}
      </StyledBlogBannerGroup>
    </>
  );
};

export default BlogBannerGroup;
