import React from "react";
import styled, { css } from "styled-components";
import JobsBannerItem from "../Molecules/JobsBannerItem";
import Picture from "../Media/nyhet3-1024x684.jpg";
import { jobsButton } from "../Content";

const StyledJobs = styled.div`
  justify-content: center;
  width: 100%;

  ${props =>
    props.cn === "jobsContainerHome" &&
    css`
      margin: 1em 0em;
    `};

  ${props =>
    props.cn === "jobsContainer" &&
    css`
      @media (min-width: 1281px) {
        margin: 2em 0em;
      }
    `};
`;

const JobsList = ({ posts, second, refDown }) => {
  let jobs = [];
  if (posts) {
    jobs = posts.map((post, index) => {
      return (
        <JobsBannerItem
          cn={second ? "jobsBannerItem" : "jobsBannerItemTwo"}
          style={{
            backgroundSize: "cover",
            backgroundImage: `linear-gradient(to right, rgba(64, 150, 191, 0.60), rgba(9, 103, 148, 0.80)), url(${post.picture})`
          }}
          key={index}
          text1={post.department}
          text2={post.title}
          text3={post.body}
          text4={jobsButton.text4}
          link={post.link}
        />
      );
    });

    if (!second) {
      jobs = [...jobs.slice(0, 3)];
      jobs.push(
        <JobsBannerItem
          cn="jobsBannerItemTwo"
          style={{
            backgroundSize: "cover",
            backgroundImage: `linear-gradient(to right, rgba(	94, 94, 94, 0.80), rgba(122, 122, 122, 0.90)), url(${Picture})`
          }}
          key="4242"
          text1={jobsButton.text1}
          text2={jobsButton.text2}
          text3="&nbsp;"
          text4="&#x27F6;"
          link="/jobb"
        />
      );
    }
  }

  return <StyledJobs ref={refDown}>{jobs}</StyledJobs>;
};

export default JobsList;
