import React from "react";
import ElementTitle from "../Molecules/ElementTitle";
import Banner from "../Organisms/Banner";
import Partner from "../Organisms/Partner";
import Reviews from "../Organisms/Reviews";
import BlogBannerGroup from "../Organisms/BlogBannerGroup";
import BusinessAreas from "../Organisms/BusinessAreas";
import JobsList from "../Organisms/JobsList";
import {
  candidatesBanner1,
  candidatesBanner2,
  candidatesBanner3,
  candidatesBanner4,
  review1,
  bannerTitleJobs,
  bannerTitleCandidates3,
  bannerTitleCandidates4
} from "../Content";

const Candidates = ({ refTop, refDown, width, posts }) => {
  return (
    <>
      <Banner
        refDown={refDown}
        refTop={refTop}
        width={width}
        first={candidatesBanner1}
      />
      <Partner refDown={refDown} />
      <Banner first={candidatesBanner2} />
      <ElementTitle {...bannerTitleCandidates3} />
      <Banner parent={candidatesBanner3} {...candidatesBanner3} />
      <Reviews {...review1} />
      <ElementTitle {...bannerTitleJobs} />
      <JobsList posts={posts} />
      <ElementTitle {...bannerTitleCandidates4} />
      <Banner parent={candidatesBanner4} {...candidatesBanner4} />
      <BlogBannerGroup cn="blogBannerGroupB" />
      <BusinessAreas />
    </>
  );
};

export default Candidates;
