import React from "react";
import styled, { css } from "styled-components";
import { partner } from "../Content";
import PartnerItem from "../Atoms/PartnerItem";
import Text from "../Atoms/Text.js";

const StyledPartner = styled.div`
  width: 100%;
  justify-content: space-around;

  ${props =>
    props.cn === "partner" &&
    css`
      height: auto;
      margin-bottom: 2em;

      @media (min-width: 961px) {
        margin-bottom: 0em;
      }
    `};

  ${props =>
    props.cn === "title" &&
    css`
      text-align: center;
      font-size: ${props => props.theme.mediumText};
      margin-top: 1em;
      margin-bottom: 1em;
      padding: 0em 0.5em;

      @media (min-width: 481px) {
        margin-top: 2em;
      }

      @media (min-width: 1281px) {
        font-size: 1.2em;
        margin-bottom: 0em;
      }
    `};

  ${props =>
    (props.cn === "one" || props.cn === "two") &&
    css`
      width: 50%;
    `};
`;

const Partner = ({ refDown }) => {
  return (
    <StyledPartner cn="partner" ref={refDown}>
      <StyledPartner cn="title">
        <Text {...partner.title} />
      </StyledPartner>
      <StyledPartner cn="one">
        <PartnerItem {...partner.picture1} />
        <PartnerItem {...partner.picture2} />
      </StyledPartner>
      <StyledPartner cn="two">
        <PartnerItem {...partner.picture3} />
        <PartnerItem {...partner.picture4} />
      </StyledPartner>
    </StyledPartner>
  );
};

export default Partner;
