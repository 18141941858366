import React from "react";
import ElementTitle from "../Molecules/ElementTitle";
import Banner from "../Organisms/Banner";
import Partner from "../Organisms/Partner";
import Reviews from "../Organisms/Reviews";
import BlogBannerGroup from "../Organisms/BlogBannerGroup";
import BusinessAreas from "../Organisms/BusinessAreas";
import { homeBanner1, homeBanner2, review1, bannerTitleJobs } from "../Content";
import JobsList from "../Organisms/JobsList";

const Home = ({ refTop, refDown, width, posts }) => {
  return (
    <>
      <Banner
        refTop={refTop}
        refDown={refDown}
        width={width}
        parent={homeBanner1}
        {...homeBanner1}
      />
      <Partner refDown={refDown} />
      <ElementTitle {...bannerTitleJobs} />
      <JobsList posts={posts} />
      <Banner parent={homeBanner2} {...homeBanner2} />
      <Reviews {...review1} />
      <BlogBannerGroup cn="blogBannerGroupB" />
      <BusinessAreas />
    </>
  );
};

export default Home;
