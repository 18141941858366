import React from "react";
import styled, { css } from "styled-components";

const StyledText = styled.div`
  justify-content: center;

  ${props =>
    props.cn === "blue" &&
    css`
      color: ${props => props.theme.colorOne};
    `};

  ${props =>
    props.cn === "grey" &&
    css`
      color: ${props => props.theme.colorTwo};
      font-weight: bold;
    `};

  ${props =>
    props.cn === "white" &&
    css`
      color: white;
    `};
`;

const Text = ({ cn, text }) => {
  return <StyledText cn={cn}>{text}</StyledText>;
};

export default Text;
