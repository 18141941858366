export const theme = {
  colorOne: "#4096bf",

  colorTwo: "#525252",

  verySmallText: "0.4em",

  smallText: "0.8em",

  mediumText: "1em",

  largeText: "1.3em",

  xLText: "1.6em",

  xLText2: "2em",

  xXLText: "2.2em",

  hugeText: "2.5em",

  bannerPadding: "2em",

  gradientBlue: `linear-gradient(
      to right,
      rgba(64, 150, 191, 0.6),
      rgba(9, 103, 148, 0.8)
    )`,

  gradientGrey: `linear-gradient(
      to right,
      rgba(94, 94, 94, 0.8),
      rgba(122, 122, 122, 0.9)
    )`,

  gradientGreyLight: `linear-gradient(0deg, #7a7a7a 0, rgba(94, 94, 94, 0) 100%)`
};
