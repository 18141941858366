import { useState, useEffect } from 'react';

const useTeamTailorData = () => {
  const [teamTailorData, setTeamTailorData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: 'GET',
        url: 'https://api.teamtailor.com/v1/jobs',
        qs: { include: 'department,locations' },
        headers: {
          'cache-control': 'no-cache',
          Connection: 'keep-alive',
          'Accept-Encoding': 'gzip, deflate',
          Host: 'api.teamtailor.com',
          'Cache-Control': 'no-cache',
          Accept: '*/*',
          'User-Agent': 'PostmanRuntime/7.19.0',
          Authorization: 'Bearer token=ZMs5wAP3-JVor8Ki0ZIPST-vJZISGD1kpJgtfMej',
          HTTP: '1.1',
          'X-Api-Version': '20161108'
        }
      };

      try {
        const response = await fetch(options.url, {
          method: options.method,
          headers: options.headers,
          qs: options.qs
        });

        const data = await response.json();
        const newData = data.data.map(sendObjects);
        const formattedData = newData.map(switchDepartments).map(fixBody);
        setTeamTailorData(formattedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const sendObjects = (num) => {
    if (num.relationships.department.data == null) {
      num.relationships.department.data = { id: "0" };
    }
    if (num.attributes.title === null) {
      num.attributes.title = "";
    }
    if (num.attributes.body === null) {
      num.attributes.body = "";
    }
    if (num.links === null) {
      num.links["careersite-job-url"] = "0";
    }
    if (num.attributes.picture === null) {
      num.attributes.picture = { standard: "" };
    }
    return {
      department: num.relationships.department.data.id,
      title: num.attributes.title,
      body: num.attributes.body,
      link: num.links["careersite-job-url"],
      picture: num.attributes.picture.standard
    };
  };

  const switchDepartments = (num) => {
    if (num.department === "8627") {
      num.department = "IT";
    } else if (num.department === "8628") {
      num.department = "Life Science";
    } else if (num.department === "0") {
      num.department = "Generellt";
    } else if (num.department === "17612") {
      num.department = "HR, Kontor & Administration";
    }
    return num;
  };

  const fixBody = (num) => {
    num.body = num.body.replace(/<[^>]+>/g, '');
    num.body = num.body.replace(/&nbsp;/g, ' ');
    num.body = num.body.replace(/[?]/g, '? ');
    num.body = num.body.replace(/[!]/g, '! ');
    num.body = num.body.replace(/[.]/g, '. ');
    num.body = num.body.substring(0, num.body.indexOf(" ", 70)) + "...";

    if (num.title.length > 40) {
      num.title = num.title.substring(0, num.body.indexOf(" ", 40)) + "...";
    }

    return num;
  };

  return teamTailorData;
};

export default useTeamTailorData;