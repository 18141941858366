import React from "react";
import styled, { css } from "styled-components";
import { blogButton } from "../Content";
import Text from "../Atoms/Text";
import Button from "../Atoms/Button";

const StyledBlogBanner = styled.div`
  ${props =>
    props.cn === "blogBanner" &&
    css`
      flex-direction: column;
      justify-content: center;
      color: white;
      padding: 2em 3em;
      width: 100%;

      background-image: ${props => props.theme.gradientGreyLight},
        url(${props => props.picture});
      background-size: cover;
    `};

  ${props =>
    props.cn === "text1" &&
    css`
      font-size: ${props => props.theme.smallText};
      padding-bottom: 5%;
    `};

  ${props =>
    props.cn === "text2" &&
    css`
      font-size: ${props => props.theme.largeText};
      font-weight: bold;
      padding-bottom: 2%;
    `};

  ${props =>
    props.cn === "text3" &&
    css`
      font-size: ${props => props.theme.mediumText};
      line-height: 1.5;
      font-style: italic;

      @media (min-width: 641px) {
        text-align: justify;
      }
    `};

  ${props =>
    props.cn === "text4" &&
    css`
      justify-content: flex-end;
      font-size: ${props => props.theme.smallText};
      padding-top: 5%;
    `};
`;

const BlogBanner = ({ picture, text1, text2, text3, link }) => {
  return (
    <StyledBlogBanner cn="blogBanner" picture={picture}>
      <StyledBlogBanner cn="text1">
        <Button cn="greyWhite" button={text1} />
      </StyledBlogBanner>
      <StyledBlogBanner cn="text2">
        <Text cn="white" text={text2} />
      </StyledBlogBanner>
      <StyledBlogBanner cn="text3">
        <Text cn="white" text={text3} />
      </StyledBlogBanner>
      <StyledBlogBanner cn="text4">
        <Button cn="white" link={link} button={blogButton} />
      </StyledBlogBanner>
    </StyledBlogBanner>
  );
};

export default BlogBanner;
