import React from "react";
import styled, { css } from "styled-components";
import Button from "../Atoms/Button";
import Picture from "../Media/blue-burger-icon.jpg";

const StyledMenu = styled.nav`
  display: flex;

  ${props =>
    props.cn === "menuTitle" &&
    css`
      width: 100%;
      align-items: center;

      @media (min-width: 961px) {
        min-height: 3vh;
      }

      @media (min-width: 1025px) {
        min-height: 4vh;
      }
    `};

  ${props =>
    props.cn === "menuBottom" &&
    css`
      width: 100%;
      @media (min-width: 961px) {
        min-height: 5vh;
      }

      @media (min-width: 1025px) {
        min-height: 8vh;
      }
    `};

  ${props =>
    props.cn === "mobileMenuList" &&
    css`
      flex-direction: column;
      background-color: white;
      font-size: ${props => props.theme.largeText};
    `};

  ${props =>
    props.cn === "mobileListItem" &&
    css`
      padding: 0.5em;
      background-color: white;
    `};

  ${props =>
    props.cn === "mobileButton" &&
    css`
      height: 2.5em;
      margin-right: 0.3em;
    `};
`;

const StyledMenuButton = styled.div`
  width: 20%;
  justify-content: center;

  ${props =>
    props.cn === "bottomButton" &&
    css`
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: ${props => props.theme.smallText};

      @media (min-width: 961px) {
        min-height: 5vh;
        padding-top: 0.5vh;
      }

      @media (min-width: 1025px) {
        align-items: center;
        min-height: 8vh;
        padding-top: 0vh;
      }
    `};

  ${props =>
    props.cn === "topButton" &&
    css`
      margin-top: 1em;
      font-size: ${props => props.theme.verySmallText};
      text-transform: uppercase;

      :nth-child(4) {
        border-right: solid 1px black;
      }
    `};
`;

const Menu = ({ cn, style, menu }) => {
  const menuTitle = menu.top.map(post => {
    return (
      <StyledMenuButton cn="topButton" key={Math.random()}>
        <Button cn="header" button={post.button} link={post.link} />
      </StyledMenuButton>
    );
  });

  const menuBottom = menu.bottom.map(post => {
    return (
      <StyledMenuButton cn="bottomButton" key={Math.random()}>
        <Button cn="header" button={post.button} link={post.link} />
      </StyledMenuButton>
    );
  });

  let mobileMenuItems = [...menu.bottom, ...menu.top.slice(3)];

  const mobileMenuList = mobileMenuItems.map(post => {
    return (
      <StyledMenu cn="mobileListItem" key={Math.random()}>
        <Button cn="header" button={post.button} link={post.link} />
      </StyledMenu>
    );
  });

  return (
    <StyledMenu cn={cn} style={style}>
      {cn === "menuTitle" && <>{menuTitle}</>}

      {cn === "menuBottom" && <>{menuBottom}</>}

      {cn === "mobileMenuList" && <>{mobileMenuList}</>}

      {cn === "mobileMenuButton" && (
        <StyledMenu
          as="img"
          cn="mobileButton"
          src={Picture}
          alt="Menu icon"
        ></StyledMenu>
      )}
    </StyledMenu>
  );
};

export default Menu;
