import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logoimg from "../Media/Uppstuk_Logo_2019_Medium.png";

const StyledLogo = styled.img`
  height: 2em;
  width: auto;
  margin-left: 0.5em;
  transform: rotate(0.7deg);

  @media (min-width: 641px) {
    height: 3em;
  }

  @media (min-width: 961px) {
    height: 3.5em;
  }

  @media (min-width: 1281px) {
    height: 4em;
  }
`;

const Logo = () => {
  return (
    <Link to="/">
      <StyledLogo src={logoimg} alt="This is a logo" />
    </Link>
  );
};

export default Logo;
