const cloudinaryId = 'dksqflfsq'

export const createCloudinaryImage = ({
    fileName = 'uppstuk_cover_fwon6q.jpg',
  }) =>
    [
      // ACCOUNT PREFIX
      // Add your own Cloudinary account ID.
      `https://res.cloudinary.com/${cloudinaryId}/image/upload`,
      // Composed Image Transformations
      `c_crop,ar_16:9`,
      `w_1600,h_900,q_100`,
      fileName,
  ].flat().filter(Boolean).join("/")

export const createCloudinaryOgImage = ({
    title,
    meta,
    backgroundFileName = 'uppstuk_cover_fwon6q.jpg',
    authorFileName = '',
  }) =>
    [
      // ACCOUNT PREFIX
      // Add your own Cloudinary account ID.
      `https://res.cloudinary.com/${cloudinaryId}/image/upload`,
      // Composed Image Transformations
      `c_crop,ar_1.91`,
      `w_1600,h_838,q_100`,
   
      'e_gradient_fade:5,y_-1.0,b_black',
      // PROFILE IMAGE
      // dynamically fetched from my twitter profile
      authorFileName && (
        [
            `/l_image:upload:${authorFileName}`,
            // Transformations
            `c_thumb,g_face,r_max,w_600,h_600,q_100`,
            // Positioning
            `fl_layer_apply,w_0.35,g_north_west,x_100,y_100`,
        ]
      ),
   
      // TITLE
      // Karla google font in light rose
      `l_text:Raleway_72_extrabold:${e(title)},co_rgb:ffffff,c_fit,w_1400,h_240`,
      // Positioning
      `fl_layer_apply,g_south_west,x_100,y_180`,
   
      // META
      // Karla, but smaller
      `l_text:Raleway_48_semibold:${e([meta].flat().filter(Boolean).join(' · '))},co_rgb:ffffff,c_fit,w_1400`,
      // Positioning
      `fl_layer_apply,g_south_west,x_100,y_100`,
      // BG
      backgroundFileName,
  ].flat().filter(Boolean).join("/")
   
  // double escape for commas and slashes
  const e = (str) => encodeURIComponent(encodeURIComponent(str))
   