import React from "react";
import styled, { css } from "styled-components";
import { blogPosts } from "../Content";
import { Route } from "react-router-dom";
import BlogBannerGroup from "./BlogBannerGroup";
import { createCloudinaryImage, createCloudinaryOgImage } from "./BlogCloudinary";
import { Helmet } from "react-helmet";

const StyledBlog = styled.div`
  justify-content: center;
  white-space: pre-wrap;

  ${props =>
    props.cn === "banner" &&
    css`
      width: 100%;
      color: white;
      padding: 3em 2rem;

      @media (min-width: 1025px) {
        padding: 3em 20vw;
      }
    `};

  ${props =>
    props.cn === "bannerContainer" &&
    css`
      width: 100%;
      padding-top: 2em;
      flex-direction: row;
      justify-content: space-between;
    `};

  ${props =>
    props.cn === "container" &&
    css`
      text-align: center;
      width: 100%;
      color: ${props => props.theme.colorTwo};
    `};

  ${props =>
    props.cn === "textBody" &&
    css`
      text-align: initial;
      width: 100%;
      line-height: 1.5;
      padding: 2em 2em;

      @media (min-width: 641px) {
        padding: 2em 7em;
        text-align: justify;
      }

      @media (min-width: 1025px) {
        width: 50%;
        padding: 2em 2em;
      }

      @media (min-width: 1281px) {
        padding: 3em 5em;
        padding-left: 10em;
        width: 60%;
        font-size: ${props => props.theme.largeText};
      }
    `};

  ${props =>
    props.cn === "rightContainer" &&
    css`
      width: 100%;

      @media (min-width: 1025px) {
        width: 50%;
        align-content: flex-start;
      }

      @media (min-width: 1281px) {
        width: 40%;
      }
    `};
`;

const StyledBlogTitle = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  font-size: ${props => props.theme.xLText};
  white-space: pre-wrap;

  :nth-child(1) {
    font-style: italic;
    padding-bottom: 0.5em;
    opacity: 0.8;
    font-size: ${props => props.theme.largeText};

    @media (min-width: 641px) {
      font-size: ${props => props.theme.largeText};
    }
  }

  :nth-child(2) {
    font-weight: bold;

    @media (min-width: 641px) {
      font-size: ${props => props.theme.xXLText};
    }
  }
`;

const StyledBlogText = styled.div`
  ${props =>
    props.cn === "bannerText" &&
    css`
      font-style: italic;

      :nth-child(1) {
        padding-right: 1em;
      }
    `};

  ${props =>
    (props.cn === "text" ||
      props.cn === "bold" ||
      props.cn === "italic" ||
      props.cn === "header") &&
    css`
      padding: 0.2em 0em;
      width: 100%;
    `};

  ${props =>
    props.cn === "bold" &&
    css`
      font-weight: bold;
    `};

  ${props =>
    props.cn === "italic" &&
    css`
      font-style: italic;
    `};

  ${props =>
    props.cn === "header" &&
    css`
      font-weight: bold;
      font-size: 1.3em;
    `};
`;

const StyledBlogImage = styled.div`
  ${props =>
    props.cn === "picture" &&
    css`
      width: 100%;
      padding: 1em 0em;

      @media (min-width: 1025px) {
        width: 100%;
        max-height: 25em;
        padding: 1em 2em;
        position: sticky;
        top: 106px;
      }

      @media (min-width: 1281px) {
        justify-content: center;
        padding: 3.5em 5em;
        max-height: 45em;
      }
    `};

  ${props =>
    props.cn === "pictureImg" &&
    css`
      width: 100%;
      object-fit: cover;
      padding: 0 0em;
      border-radius: 4px;
      overflow: hidden;
    `};

  ${props =>
    props.firstPic &&
    css`
      @media (max-width: 1024px) {
        display: none;
      }
    `}
`;

let blogArticleSelected;
let blogArticleSelectedInfo;
let blogArticleSelectedSpliced;

const blogArticleLinks = blogPosts.map(post => {
  return post[0].link;
});

const Blog = ({ blogArticle }) => {
  for (let i = 0; i < blogPosts.length; i++) {
    if (blogPosts[i][0].link === blogArticle) {
      blogArticleSelected = blogPosts[i];
      blogArticleSelectedInfo = blogPosts[i][0];
    }
  }

  blogArticleSelectedSpliced = blogArticleSelected.map(post => {
    return post;
  });

  blogArticleSelectedSpliced.splice(0, 1);

  let description = (
    blogArticleSelectedSpliced.find(el => el.bold) ||
    blogArticleSelectedSpliced.find(el => el.text) || {}
  );
  description = description["bold"] || description["text"];

  const blogArticleSelected2 = blogArticleSelectedSpliced.map((post, index) => {
    switch (Object.keys(post).toString()) {
      case "header":
        post = (
          <StyledBlogText as="h1" cn="header" key={index}>
            {Object.values(post).toString()}
          </StyledBlogText>
        );
        break;
      case "bold":
        post = (
          <StyledBlogText as="p" cn="bold" key={index}>
            {Object.values(post).toString()}
          </StyledBlogText>
        );
        break;
      case "quote":
        post = (
          <StyledBlogText as="p" cn="italic" key={index}>
            {Object.values(post).toString()}
          </StyledBlogText>
        );
        break;
      default:
        post = (
          <StyledBlogText as="p" cn="text" key={index}>
            {Object.values(post).toString()}
          </StyledBlogText>
        );
        break;
    }
    return post;
  });

  let pictureUrl = blogArticleSelectedInfo.picture
  let ogPictureUrl = pictureUrl

  if (blogArticleSelectedInfo.pictureCloudinaryFileName) {
    pictureUrl = createCloudinaryImage({
      fileName: blogArticleSelectedInfo.pictureCloudinaryFileName,
    })

    ogPictureUrl = createCloudinaryOgImage({
      title: blogArticleSelectedInfo.title,
      meta: [blogArticleSelectedInfo.author, blogArticleSelectedInfo.date],
      backgroundFileName: blogArticleSelectedInfo.pictureCloudinaryFileName,
      authorFileName: blogArticleSelectedInfo.pictureCloudinareAuthorFileName,
    })
  }

  return (
    <StyledBlog cnName="blogPage">
      <Helmet>
        <title>{blogArticleSelectedInfo.title}</title>
        {description && <meta name="description" content={description} />}
        <meta name="image" property="og:image" content={ogPictureUrl} />
        <meta name="title" property="og:title" content={blogArticleSelectedInfo.title} />
        {description && <meta name="description" property="og:description" content={description} />}
        <meta property="og:type" content="article" />
        {blogArticleSelectedInfo.link && <meta name="url" property="og:url" content={'https://uppstuk.se' + blogArticleSelectedInfo.link} />}
      </Helmet>
      <StyledBlog
        cn="banner"
        style={{
          backgroundSize: "cover",
          backgroundImage: `linear-gradient(to right, rgba(64, 150, 191, 0.60), rgba(9, 103, 148, 0.80)), 
                url(${pictureUrl})`
        }}
      >
        <StyledBlogTitle cn="bannerTitle">
          {blogArticleSelectedInfo.titleCategory}
        </StyledBlogTitle>
        <StyledBlogTitle cn="bannerTitle">
          {blogArticleSelectedInfo.title}
        </StyledBlogTitle>
        <StyledBlog cn="bannerContainer">
          <StyledBlogText cn="bannerText">
            {blogArticleSelectedInfo.author}
          </StyledBlogText>
          <StyledBlogText cn="bannerText">
            {blogArticleSelectedInfo.date}
          </StyledBlogText>
        </StyledBlog>
      </StyledBlog>
      <StyledBlog cn="container">
        <StyledBlog cn="textBody">
          {blogArticleSelected2}
          {blogArticleSelectedInfo.picture2 ? (
            <StyledBlogImage
              as="img"
              cn="pictureImg"
              src={blogArticleSelectedInfo.picture2}
              alt=""
            />
          ) : null}
          <StyledBlogText as="p" cn="italic" >
            {blogArticleSelectedInfo.author}
          </StyledBlogText>
        </StyledBlog>
        <StyledBlog cn="rightContainer">
          <StyledBlogImage cn="picture">
            <StyledBlogImage
              as="img"
              cn="pictureImg"
              src={ogPictureUrl}
              alt=""
              firstPic
            ></StyledBlogImage>
          </StyledBlogImage>
        </StyledBlog>
      </StyledBlog>
    </StyledBlog>
  );
};

const blogArticleLinksRoutes = blogArticleLinks.map((post, index) => {
  return (
    <Route key={index} exact path={post}>
      <>
        <Blog blogArticle={post} />
        <BlogBannerGroup cn="blogBannerGroupB" />
      </>
    </Route>
  );
});

export { blogArticleLinks, blogArticleLinksRoutes };
