import React from "react";
import styled, { css } from "styled-components";
import ReviewPicture from "../Atoms/ReviewPicture";
import ReviewText from "../Atoms/ReviewText";
import ElementTitle from "../Molecules/ElementTitle";

const StyledReviews = styled.div`
  align-items: center;
  text-align: center;
`;

const StyledReviewsContent = styled.div`
width: 100%;

@media (min-width: 641px) {
    width: 49%;
  }

  @media (min-width: 961px) {
      font-size: ${props => props.theme.largeText};
    }
  }
`;

const StyledReviewsText = styled(StyledReviewsContent)`
  margin: 0.5em 0em;

  @media (min-width: 641px) {
    margin: 0.5em 1%;
  }

  @media (min-width: 1025px) {
    padding-right: 1.5em;
  }

  ${props =>
    props.cn === "personellText" &&
    css`
      @media (min-width: 1281px) {
        width: 23%;
      }
    `};

  ${props =>
    props.cn === "partnersText" &&
    css`
      @media (min-width: 1025px) {
        width: 40%;

        :nth-child(3) {
          margin-bottom: 3em;
        }
      }

      @media (min-width: 1281px) {
        width: 35%;
      }
    `};
`;

const StyledReviewsPicture = styled(StyledReviewsContent)`
  height: 15em;

  @media (min-width: 641px) {
    margin-bottom: 1em;
  }

  @media (min-width: 1281px) {
    width: 23%;
  }

  ${props =>
    props.cn === "partnersPicture" &&
    css`
      margin-top: 2em;
      justify-content: center;
      align-items: center;

      @media (min-width: 641px) {
        margin-bottom: 1em;
        align-items: flex-end;
      }

      @media (min-width: 1025px) {
        :nth-child(2) {
          margin-bottom: 3em;
        }
      }

      @media (min-width: 1281px) {
        justify-content: flex-end;
        align-items: center;
        padding-right: 2em;
      }
    `};
`;

const Reviews = ({ cn, title, reviews }) => {
  let classPicture = "";
  let classText = "";

  switch (cn) {
    case "reviewPersonell":
      classPicture = "personellPicture";
      classText = "personellText";
      break;
    default:
      classPicture = "partnersPicture";
      classText = "partnersText";
  }

  const reviewToReturn = reviews.map((post, index) => {
    return (
      <React.Fragment key={index}>
        <StyledReviewsPicture cn={classPicture}>
          <ReviewPicture {...post.picture} />
        </StyledReviewsPicture>
        <StyledReviewsText cn={classText}>
          <ReviewText {...post.text} />
        </StyledReviewsText>
      </React.Fragment>
    );
  });

  return (
    <StyledReviews cn={cn}>
      <ElementTitle {...title} />
      {reviewToReturn}
    </StyledReviews>
  );
};

export default Reviews;
