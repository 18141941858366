import React from "react";
import ElementTitle from "../Molecules/ElementTitle";
import BlogBannerGroup from "../Organisms/BlogBannerGroup";
import BlogPreviews from "../Organisms/BlogPreviews";
import { bannerTitleBlog } from "../Content";
import { Helmet } from "react-helmet";

const Blog = ({ refDown, refTop, width }) => {
  return (
    <>
      <Helmet><title>Blogg</title></Helmet>
      <BlogBannerGroup
        cn="blogBannerGroupA"
        refDown={refDown}
        refTop={refTop}
        width={width}
      />
      <ElementTitle {...bannerTitleBlog} refDown={refDown} />
      <BlogPreviews />
    </>
  );
};

export default Blog;
